.newscard-related {
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: 295px;
    box-sizing: border-box;
}

.newscard-related .hero-related {
    height: 153px;
    object-fit: cover;
    position: relative;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

.newscard-related .hero-related img {
    flex-shrink: 0; 
    min-width: 100%; 
    min-height: 100%;
    cursor: pointer;
    align-self: stretch;
  /* height: 247px; */
    max-height: 247px;
    object-fit: cover;
    position: relative;
    width: 100%;
}

.newscard-related .infocard {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f5f5f5;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 30px 15px 30px 15px;
    cursor: pointer;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.newscard-related .category-container {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 5px;
    position: relative;
}

.newscard-related .category {
    color: #141414;
    font-family: "Area Variable-ExtraBold", Helvetica;
    font-size: 11px;
    font-weight: 800;
    letter-spacing: 1.1px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.newscard-related .underline {
    align-self: stretch;
    background-color: #dc573a;
    height: 1px;
    position: relative;
    width: 100%;
}

.newscard-related .card-copy-container {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 30px;
    position: relative;
    width: 100%;
}

.newscard-related .headline-related {
    align-self: stretch;
    color: #141414;
    font-family: "Area Variable-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
}