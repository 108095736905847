
.App {
  text-align: left;
}

p {
  margin: 0px;
  padding: 0px;
}

@import url("https://p.typekit.net/p.css?s=1&k=mok3zrg&ht=tk&f=53145&a=6562256&app=typekit&e=css");

@font-face {
  font-family:"area-variable";
  src:url("https://use.typekit.net/af/cb9059/0000000000000000774ee012/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/cb9059/0000000000000000774ee012/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/cb9059/0000000000000000774ee012/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
}

.tk-area-variable { font-family: "area-variable",sans-serif; 
  font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
}