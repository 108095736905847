@import url(https://p.typekit.net/p.css?s=1&k=mok3zrg&ht=tk&f=53145&a=6562256&app=typekit&e=css);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.App {
  text-align: left;
}

p {
  margin: 0px;
  padding: 0px;
}

@font-face {
  font-family:"area-variable";
  src:url("https://use.typekit.net/af/cb9059/0000000000000000774ee012/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/cb9059/0000000000000000774ee012/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/cb9059/0000000000000000774ee012/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
}

.tk-area-variable { font-family: "area-variable",sans-serif; 
  font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
}
.logotemp1Icon {
    width: 93px;
    position: relative;
    height: 29px;
    overflow: hidden;
    flex-shrink: 0;
}
.header {
    align-self: stretch;
    background-color: #141414;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 32px 100px;
    position: fixed;
    width: 100%;
    z-index: 99;
    box-sizing: border-box;
    transition: 0.4s;
    cursor: pointer;
}
.unbiased {
    position: relative;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 800;
}
.allTheNews {
    position: relative;
    font-size: 34px;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 600;
    
}
.heroSection {
    align-self: stretch;
    height: 400px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 34px;
    gap: 34px;
    background-image: url(/static/media/hero.0a5a9606.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    margin-top: 50px;
}
.navtex {
    position: relative;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
.largeNavbutton {
    border-radius: 5px;
    background-color: #f3f3f3;
    height: 37px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
    box-sizing: border-box;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 700;
    text-transform: uppercase;
    cursor: pointer;
}
.categories {
    width: 862px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
}



.header1 {
    align-self: stretch;
    background-color: #141414;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 32px 100px;
    font-size: 12px;
    color: #141414;
}
.section {
    width: 100%;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    font-size: 99px;
    color: #fff;
    font-family: 'Area Variable';
    background-color: #141414;
}


@media (max-width: 900px) {
    .categories {
        width: 100%;
        padding: 0px 20px;
    }
    .largeNavbutton {
        padding: 0px 8px;
    }
    .header1 {
        font-size: 9px;
        padding: 32px 20px;
    }
    .unbiased {
        font-size: 30px;
    }
    .allTheNews {
        font-size: 24px;
    }
}

.news-module-NP {
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  cursor: pointer;
}


.news-module-NP .image {
  align-self: stretch;
  /* height: 247px; */
  max-height: 247px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.news-module-NP .infocard {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f5f5f5;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  grid-gap: 14px;
  gap: 14px;
  padding: 37px 35px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.news-module-NP .category-container {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  position: relative;
}

.news-module-NP .category {
  color: #141414;
  /* font-family: "Basic Sans-SemiBold", Helvetica; */
  font-family: area-variable, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  line-height: normal;
  margin-top: 0px;
  position: relative;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.news-module-NP .frame {
  align-self: stretch;
  background-color: #dc573a;
  height: 1px;
  position: relative;
  width: 100%;
}

.news-module-NP .copy-container {
  align-items: flex-start;
  /* align-self: stretch; */
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  grid-gap: 14px;
  gap: 14px;
  position: relative;
  width: 100%;
}

.news-module-NP .headline {
  /* align-self: start; */
  color: #141414;
  /* font-family: "Basic Sans-SemiBold", Helvetica; */
  font-family: area-variable, sans-serif;
  font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 700;
  font-size: 24px;
  /* font-weight: 700; */
  letter-spacing: 0;
  line-height: 135%;
  margin-top: -1px;
  position: relative;
}

.news-module-NP .summary {
  /* align-self: stretch; */
  color: #141414;
  /* font-family: "Basic Sans-Regular", Helvetica; */
  font-family: area-variable, sans-serif;
  font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
  font-size: 18px;
  /* font-weight: 400; */
  letter-spacing: 0;
  line-height: 160%;
  position: relative;
}

@media (max-width: 900px) {
  .news-module-NP .headline {
    font-size: 20px;
  }
  .news-module-NP .summary {
    font-size: 16px;
  }
}

.header {
    align-self: stretch;
    background-color: #141414;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 100px;
    position: fixed;
    width: 100%;
    z-index: 99;
    box-sizing: border-box;
}

.backButton {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    color: white;
    width: 93px;
    position: relative;
    height: 29px;
    overflow: hidden;
    flex-shrink: 0;
    align-content: left;
}

@media (max-width: 900px) {
    .backButton {
        margin-left: 5px;
        padding-left: 5px;
    }

    .header {
        padding: 0px 20px;
    }
}

.column3 {
    align-self: stretch;
flex: 1 1;
position: relative;
overflow: hidden;
}

.column2 {
    cursor: pointer;
    flex: 1 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.column1 {
    flex: 1 1;
overflow: hidden;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
}
.hero-image-icon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 426px;
    flex-shrink: 0;
    object-fit: cover;
}

.category {
    position: relative;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 800;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 800;
}

.headline {
    align-self: stretch;
    position: relative;
    font-size: 60px;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
}

@media screen and (max-width: 900px) {
    .headline {
        font-size: 40px;
    }
}

.puplished-date {
    position: relative;
    line-height: 150%;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
}

.puplished-date-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
}

.header-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 25px;
    gap: 25px;
}

.vector-icon {
    width: 19px;
    position: relative;
    height: 11px;
    object-fit: contain;
}

.category-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 25px;
}

.collapsing-list {
    align-self: stretch;
    border-radius: 5px;
    background-color: #f5f5f5;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding: 13px 40px;
}

.h2 {
    align-self: stretch;
    position: relative;
    line-height: 41px;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 800;
}

.paragraph {
    align-self: stretch;
    position: relative;
    font-size: 18px;
    line-height: 150%;
    white-space: pre-wrap;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
}

@media screen and (max-width: 900px) {
    .paragraph {
        font-size: 16px;
    }
    
    .article-container {
        font-size: 20px;
    }
    .h2 {
        font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 700;
    }
}

.article-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 19px;
    gap: 19px;
    font-size: 30px;
}

.content-wrapper {
    width: 740px;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 49px;
    gap: 49px;
    text-align: left;
    font-size: 11px;
    color: #141414;
    font-family: 'Area Variable';
}

.body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 100px;
    gap: 100px;
    width: 100%;
    position: relative;
}

.related-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    min-width: 250px;
}
/* .related-container {
    display: flex;
    flex-direction: column;
    align-items: center;
} */
.related-container .frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    position: relative;
    width: 100%;
}

/* .header-related {
    text-align: ;
} */

.related-container .header-related {
    color: #141414;
    font-family: "Area Variable-Bold", Helvetica;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 41px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.card-container {
    display: flex;
    justify-content: center;
}

.related-container .card-container {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    grid-gap: 20px;
    gap: 20px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* flex-wrap: wrap; */
    
}

@media screen and (max-width: 900px) {
    .related-container .card-container {
        width: 100%;
        /* flex-direction: column; */

    }

    .h2 {
        font-size: 30px;
    }
}

.related-container .newscard-related-instance {
    flex: 1 1 !important;
    flex-grow: 1 !important;
    width: unset !important;
}

/* @media (max-width: 900px) {
    .related-container .newscard-related-instance  {
      width: 50%; 
    }
  } */


@media (max-width: 900px) {
    .content-wrapper {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }   
}
.accordion {
    overflow: hidden; /* Ensure no overflow */
    border-radius: 10px; /* Add border radius */
    font-family: area-variable, sans-serif;
  }
  
  .accordion-btn {
    background-color: #f5f5f5;
    color: #333;
    cursor: pointer;
    padding: 20px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    display: flex;
    justify-content: space-between; /* Align items horizontally */
    align-items: center; /* Align items vertically */
    transition: background-color 0.3s ease-out; /* Smooth background color transition */
    font-family: area-variable, sans-serif;
    box-sizing: border-box; 
  }
  .accordion-text {
    flex: 1 1; /* Take up remaining space */
    font-size: 16px;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 800;
  }
  .accordion-icon {
    /*transition: transform 0.3s ease-out /* Smooth icon rotation transition */
    transition: transform 0.3s cubic-bezier(.18,.76,.35,.96);
  }
  
  .list {
    padding: 0;
  }
  /* .accordion-btn:hover {
    background-color: #ddd;}  */
  
  .list-wrapper {
    padding: 25px;
    padding-bottom: 0px;
  
    /*
    border-top: 2px solid #ccc; /* Grey line at the top of the list */
    
    font-size: 14px;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
  }
  .accordion-content {
    background-color: #f5f5f5;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s cubic-bezier(.18,.76,.35,.96); /* Smooth height transition */
    box-sizing: border-box; 
  }
  
  .accordion-content li {
    margin-bottom: 20px; /* Space between list items */
    box-sizing: border-box; 
  }
  
  .accordion.active .accordion-content {
    max-height: 1000px; /* Adjust to a reasonable max height */
  
  }
.newscard-related {
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: 295px;
    box-sizing: border-box;
}

.newscard-related .hero-related {
    height: 153px;
    object-fit: cover;
    position: relative;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

.newscard-related .hero-related img {
    flex-shrink: 0; 
    min-width: 100%; 
    min-height: 100%;
    cursor: pointer;
    align-self: stretch;
  /* height: 247px; */
    max-height: 247px;
    object-fit: cover;
    position: relative;
    width: 100%;
}

.newscard-related .infocard {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f5f5f5;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding: 30px 15px 30px 15px;
    cursor: pointer;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.newscard-related .category-container {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    position: relative;
}

.newscard-related .category {
    color: #141414;
    font-family: "Area Variable-ExtraBold", Helvetica;
    font-size: 11px;
    font-weight: 800;
    letter-spacing: 1.1px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.newscard-related .underline {
    align-self: stretch;
    background-color: #dc573a;
    height: 1px;
    position: relative;
    width: 100%;
}

.newscard-related .card-copy-container {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
    position: relative;
    width: 100%;
}

.newscard-related .headline-related {
    align-self: stretch;
    color: #141414;
    font-family: "Area Variable-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
}
