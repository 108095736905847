.hero-image-icon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 426px;
    flex-shrink: 0;
    object-fit: cover;
}

.category {
    position: relative;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 800;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 800;
}

.headline {
    align-self: stretch;
    position: relative;
    font-size: 60px;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
}

@media screen and (max-width: 900px) {
    .headline {
        font-size: 40px;
    }
}

.puplished-date {
    position: relative;
    line-height: 150%;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
}

.puplished-date-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
}

.header-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px;
}

.vector-icon {
    width: 19px;
    position: relative;
    height: 11px;
    object-fit: contain;
}

.category-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 25px;
}

.collapsing-list {
    align-self: stretch;
    border-radius: 5px;
    background-color: #f5f5f5;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding: 13px 40px;
}

.h2 {
    align-self: stretch;
    position: relative;
    line-height: 41px;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 800;
}

.paragraph {
    align-self: stretch;
    position: relative;
    font-size: 18px;
    line-height: 150%;
    white-space: pre-wrap;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
}

@media screen and (max-width: 900px) {
    .paragraph {
        font-size: 16px;
    }
    
    .article-container {
        font-size: 20px;
    }
    .h2 {
        font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 700;
    }
}

.article-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 19px;
    font-size: 30px;
}

.content-wrapper {
    width: 740px;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 49px;
    text-align: left;
    font-size: 11px;
    color: #141414;
    font-family: 'Area Variable';
}

.body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 100px;
    width: 100%;
    position: relative;
}

.related-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    min-width: 250px;
}
/* .related-container {
    display: flex;
    flex-direction: column;
    align-items: center;
} */
.related-container .frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
}

/* .header-related {
    text-align: ;
} */

.related-container .header-related {
    color: #141414;
    font-family: "Area Variable-Bold", Helvetica;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 41px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.card-container {
    display: flex;
    justify-content: center;
}

.related-container .card-container {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* flex-wrap: wrap; */
    
}

@media screen and (max-width: 900px) {
    .related-container .card-container {
        width: 100%;
        /* flex-direction: column; */

    }

    .h2 {
        font-size: 30px;
    }
}

.related-container .newscard-related-instance {
    flex: 1 !important;
    flex-grow: 1 !important;
    width: unset !important;
}

/* @media (max-width: 900px) {
    .related-container .newscard-related-instance  {
      width: 50%; 
    }
  } */


@media (max-width: 900px) {
    .content-wrapper {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }   
}