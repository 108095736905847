.logotemp1Icon {
    width: 93px;
    position: relative;
    height: 29px;
    overflow: hidden;
    flex-shrink: 0;
}
.header {
    align-self: stretch;
    background-color: #141414;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 32px 100px;
    position: fixed;
    width: 100%;
    z-index: 99;
    box-sizing: border-box;
    transition: 0.4s;
    cursor: pointer;
}
.unbiased {
    position: relative;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 800;
}
.allTheNews {
    position: relative;
    font-size: 34px;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 600;
    
}
.heroSection {
    align-self: stretch;
    height: 400px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 34px;
    background-image: url('./assets/hero.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    margin-top: 50px;
}
.navtex {
    position: relative;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
.largeNavbutton {
    border-radius: 5px;
    background-color: #f3f3f3;
    height: 37px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
    box-sizing: border-box;
    font-family: area-variable, sans-serif;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 700;
    text-transform: uppercase;
    cursor: pointer;
}
.categories {
    width: 862px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
}



.header1 {
    align-self: stretch;
    background-color: #141414;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 32px 100px;
    font-size: 12px;
    color: #141414;
}
.section {
    width: 100%;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    font-size: 99px;
    color: #fff;
    font-family: 'Area Variable';
    background-color: #141414;
}


@media (max-width: 900px) {
    .categories {
        width: 100%;
        padding: 0px 20px;
    }
    .largeNavbutton {
        padding: 0px 8px;
    }
    .header1 {
        font-size: 9px;
        padding: 32px 20px;
    }
    .unbiased {
        font-size: 30px;
    }
    .allTheNews {
        font-size: 24px;
    }
}
