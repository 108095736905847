.header {
    align-self: stretch;
    background-color: #141414;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 100px;
    position: fixed;
    width: 100%;
    z-index: 99;
    box-sizing: border-box;
}

.backButton {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    color: white;
    width: 93px;
    position: relative;
    height: 29px;
    overflow: hidden;
    flex-shrink: 0;
    align-content: left;
}

@media (max-width: 900px) {
    .backButton {
        margin-left: 5px;
        padding-left: 5px;
    }

    .header {
        padding: 0px 20px;
    }
}

.column3 {
    align-self: stretch;
flex: 1;
position: relative;
overflow: hidden;
}

.column2 {
    cursor: pointer;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.column1 {
    flex: 1;
overflow: hidden;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
}