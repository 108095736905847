.accordion {
    overflow: hidden; /* Ensure no overflow */
    border-radius: 10px; /* Add border radius */
    font-family: area-variable, sans-serif;
  }
  
  .accordion-btn {
    background-color: #f5f5f5;
    color: #333;
    cursor: pointer;
    padding: 20px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    display: flex;
    justify-content: space-between; /* Align items horizontally */
    align-items: center; /* Align items vertically */
    transition: background-color 0.3s ease-out; /* Smooth background color transition */
    font-family: area-variable, sans-serif;
    box-sizing: border-box; 
  }
  .accordion-text {
    flex: 1; /* Take up remaining space */
    font-size: 16px;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 800;
  }
  .accordion-icon {
    /*transition: transform 0.3s ease-out /* Smooth icon rotation transition */
    transition: transform 0.3s cubic-bezier(.18,.76,.35,.96);
  }
  
  .list {
    padding: 0;
  }
  /* .accordion-btn:hover {
    background-color: #ddd;}  */
  
  .list-wrapper {
    padding: 25px;
    padding-bottom: 0px;
  
    /*
    border-top: 2px solid #ccc; /* Grey line at the top of the list */
    
    font-size: 14px;
    font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
  }
  .accordion-content {
    background-color: #f5f5f5;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s cubic-bezier(.18,.76,.35,.96); /* Smooth height transition */
    box-sizing: border-box; 
  }
  
  .accordion-content li {
    margin-bottom: 20px; /* Space between list items */
    box-sizing: border-box; 
  }
  
  .accordion.active .accordion-content {
    max-height: 1000px; /* Adjust to a reasonable max height */
  
  }