.news-module-NP {
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  cursor: pointer;
}


.news-module-NP .image {
  align-self: stretch;
  /* height: 247px; */
  max-height: 247px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.news-module-NP .infocard {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f5f5f5;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  padding: 37px 35px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.news-module-NP .category-container {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  position: relative;
}

.news-module-NP .category {
  color: #141414;
  /* font-family: "Basic Sans-SemiBold", Helvetica; */
  font-family: area-variable, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  line-height: normal;
  margin-top: 0px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.news-module-NP .frame {
  align-self: stretch;
  background-color: #dc573a;
  height: 1px;
  position: relative;
  width: 100%;
}

.news-module-NP .copy-container {
  align-items: flex-start;
  /* align-self: stretch; */
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  position: relative;
  width: 100%;
}

.news-module-NP .headline {
  /* align-self: start; */
  color: #141414;
  /* font-family: "Basic Sans-SemiBold", Helvetica; */
  font-family: area-variable, sans-serif;
  font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 700;
  font-size: 24px;
  /* font-weight: 700; */
  letter-spacing: 0;
  line-height: 135%;
  margin-top: -1px;
  position: relative;
}

.news-module-NP .summary {
  /* align-self: stretch; */
  color: #141414;
  /* font-family: "Basic Sans-Regular", Helvetica; */
  font-family: area-variable, sans-serif;
  font-variation-settings: "INKT" 0, "slnt" 0, "wdth" 100, "wght" 400;
  font-size: 18px;
  /* font-weight: 400; */
  letter-spacing: 0;
  line-height: 160%;
  position: relative;
}

@media (max-width: 900px) {
  .news-module-NP .headline {
    font-size: 20px;
  }
  .news-module-NP .summary {
    font-size: 16px;
  }
}
